<template>
  <modal @before-open="() => {
           form.sum = car.price;

           // if (car.owner) {
           //   client = car.owner;
           //   form.client_name = car.owner.name;
           //   form.client_phone = '+' + car.owner.phone.toString();
           // }

           getClients();
         }"
         :adaptive="true"
         :clickToClose="true"
         :draggable="false"
         :name="'car_sold_modal_' + car.id"
         :resizable="false"
         :scrollable="false"
         :shiftX="0.5"
         :shiftY="0.5"
         :height="'auto'"
         overlayTransition="fade"
         transition="fade">
    <div class="box box-primary box-modal mb-0">
      <div class="box-header with-border mb-3">
        <h4 class="font-medium">
          Указать продажу
          <small class="text-muted">
            <br/>
            #{{ car.id }} {{ car.title }}
          </small>
        </h4>
      </div>

      <div class="box-body">
        <form @keydown="form.onKeydown($event)" @submit.prevent="saveCarSale">
          <div class="form-group">
            <label class="font-medium font-small">
              Покупатель
            </label>
            <div class="row">
              <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('client_id') }">
                <multiselect :allow-empty="true"
                             :options="clients"
                             :searchable="true"
                             :show-labels="true"
                             @input="() => {
                                   form.client_id = client ? client.id : null;
                                   form.client_name = client ? client.name : null;
                                   form.client_phone = client ? client.phone.toString() : '';
                                 }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Выберите существующего клиента"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Выберите существующего клиента"
                             track-by="id"
                             v-model="client">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }}, {{ props.option.phone }}
                  </template>
<!--                  <template slot="option" slot-scope="props">-->
<!--                    {{ props.option.name }}, <span class="text-muted">{{ props.option.phone }}</span>-->
<!--                  </template>-->
                </multiselect>
                <HasError :form="form" field="client_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-sm-12"
                   v-bind:class="{ 'has-error': form.errors.has('client_name') }">
                <input class="form-control"
                       :disabled="Boolean(form.client_id)"
                       name="client_name"
                       placeholder="ФИО нового клиента"
                       title="ФИО нового клиента"
                       type="text"
                       v-model="form.client_name">
                <HasError :form="form" field="client_name" />
              </div>

              <div class="col-md-6 col-sm-12"
                   v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
                <vue-tel-input :disabled="Boolean(form.client_id)"
                               name="client_phone"
                               v-bind="phone_config"
                               v-model="form.client_phone">
                </vue-tel-input>
                <HasError :form="form" field="client_phone" />
              </div>
            </div>
          </div>

          <hr />

          <div class="form-group">
            <label class="font-medium font-small">
              Сумма продажи
            </label>
            <div class="input-group mb-3">
              <input :max="999999999"
                     :min="1"
                     class="form-control"
                     name="sum"
                     placeholder="Сумма"
                     required
                     title="Сумма"
                     type="number"
                     v-model="form.sum">
              <span class="input-group-text">$</span>
            </div>
            <HasError :form="form" field="sum" />
          </div>

          <div class="form-group">
            <label class="font-medium font-small">
              Сумма владельцу
            </label>
            <div class="input-group mb-3">
              <input :max="999999999"
                     :min="1"
                     class="form-control"
                     name="owner_sum"
                     placeholder="Сумма владельцу"
                     required
                     title="Сумма владельцу"
                     type="number"
                     v-model="form.owner_sum">
              <span class="input-group-text">$</span>
            </div>
            <HasError :form="form" field="sum" />
          </div>

          <div class="form-group">
            <input class="pointer"
                   id="sold_for_crypto"
                   style="transform: scale(1.2);"
                   type="checkbox"
                   v-model="form.sold_for_crypto">
            <label for="sold_for_crypto" class="text-muted margin-l-5 padding-l-10">
              Продано за криптовалюту
            </label>
            <HasError :form="form" field="sum" />
          </div>

          <div class="form-group" v-if="form.sum && form.owner_sum">
            <h5>
              Сумма комиссии: <strong class="font-medium">{{ (form.sum - form.owner_sum) }}</strong>$
            </h5>
          </div>

          <div class="form-group">
                  <textarea class="form-control"
                            placeholder="Комментарий"
                            rows="3"
                            v-model="form.comment">
                  </textarea>
            <HasError :form="form" field="comment" />
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>

          <button class="btn btn-default btn-lg pull-right margin-r-10"
                  title="Отменить и закрыть окно"
                  type="button"
                  v-on:click="hideCarFinanceOperationsModal(car.id)">
            <i class="far fa-window-close"></i>
          </button>

          <br />
          <br />
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import Form from "vform";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import phone_config from "@/assets/data/vue-tel-input-config.json";

Vue.use(VModal);

Form.axios = API.apiClient;

export default {
  name: "car-sold-modal",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
  },

  props: {
    car: {
      type: Object,
    },
  },

  data: () => ({
    form: new Form({
      client_id: null,
      client_name: null,
      client_phone: null,
      sum: null,
      owner_sum: null,
      sold_for_crypto: false,
      comment: null,
    }),

    clients: [],
    client: null,

    phone_config: phone_config,
  }),

  computed: {
    validateData: function() {
      return (this.form.client_id || (this.form.client_name && this.form.client_phone)) && this.form.sum &&
        this.form.owner_sum;
    },
  },

  methods: {
    async getClients() {
      try {
        const clients_response = await API.apiClient.get("/clients", {
          params: {
            "is_list_load": 1
          },
        });

        this.clients = clients_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async hideCarFinanceOperationsModal(carId) {

      this.$modal.hide("car_sold_modal_" + carId);
    },
    async saveCarSale() {
      await this.form.post("/car/" + this.car.id + "/sold/add").then(() => {
        showSuccess();
        window.location.reload();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>

</style>
